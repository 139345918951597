import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import { transition, Media } from '@raysync/common/theme'
import { H2, H4, Button, Link } from '@raysync/common/components'
import { CmsContentService } from '@raysync/common/services'

const Container = styled.div`
  position: relative;
  padding: 18px 40px 30px 30px;
  box-shadow: 0px 2px 40px 0px #e6e6e6;
  border-radius: 16px;
  color: ${p => p.theme.color.textDarkPlus};
  margin-bottom: 30px;
  ${Media.phone`
    font-size: .24rem;
    padding: .34rem .3rem .4rem;
    border-radius: .16rem;
    margin-bottom: .4rem;
  `}
`
const Anchor = styled.span`
  display: inline-block;
  position: absolute;
  top: -90px;
`
const Category = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  img {
    margin-right: 10px;
  }
  ${Media.phone`
    margin-bottom: .24rem;
    img {
      width: .3rem;
      height: .3rem;
      margin-right: .16rem;
    }
  `}
`
const Content = styled.div`
  display: flex;
  ${Media.phone`
    flex-wrap: wrap;
  `}
`
const ImgBox = styled.div`
  overflow: hidden;
  width: 480px;
  min-width: 480px;
  max-height: 280px;
  margin-right: 46px;
  border-radius: 8px;
  img {
    min-width: 480px;
    height: 100%;
    object-fit: cover;
    transition: ${transition(['transform'])};
    :hover {
      transform: scale(1.2);
    }
  }
  ${Media.phone`
    max-width: 100%;
    max-height: 3.7rem;
    margin-right: unset;
    img {
      min-width: unset;
    }
  `}
`
const Intro = styled.div`
  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-top: 5px;
  }
  h4 {
    font-size: 16px;
    font-weight: bold;
    margin: 15px 0;
  }
  p {
    color: #636772;
    margin-bottom: 18px;
  }
  ${Media.phone`
    h2 {
      font-size: .36rem;
      margin-top: .36rem;
    }
    h4 {
      font-size: .28rem;
      margin: .24rem 0;
    }
    p {
      margin-bottom: .36rem;
    }
  `}
`
const CodeBlock = props => {
  return <span dangerouslySetInnerHTML={{ __html: props.value }}></span>
}

export const ListItem = props => {
  const { companyName, content, intro, slug, categoryName, categoryIcon, categorySlug } = props
  const coverImgSrc = CmsContentService.getCoverImg(content)
  return (
    <Container>
      <Anchor id={categorySlug} />
      <Category>
        <img src={categoryIcon} alt={categoryName} />
        <p>{categoryName}</p>
      </Category>
      <Content>
        <ImgBox>
          <img src={coverImgSrc} alt={companyName} />
        </ImgBox>
        <Intro>
          <H2>{companyName}</H2>
          <H4>客户痛点：</H4>
          <ReactMarkdown source={intro} escapeHtml={false} renderers={{ code: CodeBlock }} />
          <Link to={`/example/${slug}.html`}>
            <Button>查看具体解决方案</Button>
          </Link>
        </Intro>
      </Content>
    </Container>
  )
}
