import React from 'react'
import { InviteBanner } from '../../components/banners'
import { Swiper } from '@raysync/common/components'
import { useSmoothScroll } from '@raysync/common/hooks'
import Layout from '../../components/layout'
import { Banner } from './banner'
import { Categories } from './categories'
import { ListItem } from './list-item'
import { ExampleWrapper } from './atoms'

function Example(props) {
  const { location, pathContext } = props
  useSmoothScroll()
  return (
    <Layout pageType='example' location={location} showH1={false}>
      <Banner />
      <ExampleWrapper>
        <Categories categories={pathContext.categories} />
        {pathContext.details.map((article, index) => {
          const category = pathContext.categories.find(item => item.slug === article.category.slug)
          return (
            <ListItem
              key={index}
              companyName={article.companyName}
              categoryName={category.name}
              categoryIcon={category.icon.file.url}
              categorySlug={article.category.slug}
              slug={article.slug}
              content={article.content && article.content.content}
              intro={article.intro && article.intro.intro}
            />
          )
        })}
      </ExampleWrapper>
      <Swiper>
        <InviteBanner type='example' />
      </Swiper>
    </Layout>
  )
}

export default Example
